export const CreateGoogleUser = `
    #graphql
    mutation Users($data: create_Users_input!) {
        create_Users_item(data: $data) {
          id       
          first_name
          last_name
          email
          phone_number
          website
          organization_name
          leader_name
          address_city
          address_state
          address_street
          address_zip_code
          logo
        
        }
    }
`;

/**
 * GraphQL query string for retrieving homepage announcement for a specific site.
 *
 * @type {string}
 */
export const SiteAnnoucementDetails = `
  #graphql
  query SiteAnnoucementDetails($siteName: String){
    Sites(filter:{ domain_name: { _eq: $siteName } }) {
      id
      site_name
      domain_name
      homepage {
        announcement_banner
        announcement_text
      }
    }
  }
`;

/**
 * GraphQL query string for retrieving homepage content for a specific site.
 *
 * @type {string}
 */
export const SiteHomepageContent = `
  #graphql
  query HomePageContent($siteName: String) {
    Sites(filter:{ domain_name: { _eq: $siteName } }) {
      id
      site_name
      domain_name
      homepage {
        welcome_text
        headline
        subheadline
        featured_categories {
          Product_categories_cat_id(
            filter: { status: { _in: ["published"] }, site_id: { Sites_id: { domain_name: { _eq: $siteName } } } }
          ) {
            cat_id
            category_name
            slug
            sort
            child {
              cat_id
            }
            parent {
              cat_id
            }
            category_icon {
              filename_disk
            }
            icon
          }
        }
        featured(limit: 6) {
          products_id(
            filter: { status: { _in: ["published"] }, site_id: { Sites_id: { domain_name: { _eq: $siteName } } } }
          ) {
            id
            thumbnail {
              id
            }
            title
            slug
            description
            price
            category {
              Product_categories_cat_id {
                cat_id
                category_name
                slug
                child {
                  cat_id
                  category_name
                  slug
                }
              }
            }
            related_products {
              related_products_id {
                id
                price
                slug
                title
                thumbnail {
                  id
                }
              }
            }
            product_type {
              name
            }
            product_language{
              name
            }
            date_created
          }
        }
      }
    }
    products(
      filter: {
        status: { _in: ["published"] }
        product_type: { id: { _neq: null } }
        listed: { _eq: true }
        site_id: { Sites_id: { domain_name: { _eq: $siteName } } }
      }
      limit: 6
      sort: "-id"
    ) {
      id
      thumbnail {
        id
      }
      title
      slug
      description
      price
      category {
        Product_categories_cat_id {
          cat_id
          category_name
          slug
          child {
            cat_id
            category_name
            slug
          }
        }
      }
      related_products {
        related_products_id {
          id
          price
          slug
          title
          thumbnail {
            id
          }
        }
      }
      product_type {
        name
      }
      product_language{
        name
      }
      date_created
    }
  }
`;

export const HomepageContent = `
  #graphql
  query HomePageContent {      
    homepage
    {
      welcome_text
      
      featured_products{
        id
        thumbnail
        {
          id
        }
        title
        slug
        description
        price
        category{
          Product_categories_cat_id
          {
            cat_id
            category_name
            slug
          }
        }
        product_type {
          name
        }
      }
      featured_categories
      {
        cat_id
        category_name
        slug
        category_icon
        {
          filename_disk
        }
        icon
      }
    }
    products(
      filter: {
        status: { _in: ["published"] }
        product_type: { id: { _neq: null } }
        listed: { _eq: true}
      }
      limit:6,
      sort:"-id"
      ){
      id
      thumbnail{
        id
      }
      title
      slug
      description
      price
      category{
        Product_categories_cat_id{
          cat_id
          category_name
          slug
        }
      }
      product_type {
        name
      }
    }
    trending_designs{
      products{
        id
        thumbnail{
          id
        }
        title
        slug
        description
        price
        category{
          Product_categories_cat_id{
            cat_id
            category_name
            slug
          }
        }
        product_type {
          name
        }
      }
    }
  }
`;
export const SingleProductQuery = `
    #graphql
    query SingleProduct($product_slug: String, $site_name: String) {
        products(filter: {slug: {_eq: $product_slug}, site_id: { Sites_id: { domain_name: { _eq: $site_name } } }}) {
            id
            title
            price
            DesignJSON
            description
            slug
            status
            show_replace_image_popup
            no_brand_watermark
            thumbnail{
                id
            }
            category{
              Product_categories_cat_id {
                cat_id
                category_name
                slug
                child{
                  category_name
                  cat_id
                }
              }
            }
            related_products(limit: 7) {
               related_products_id{
                  id
                  price
                  slug                  
                  title
                  thumbnail {
                    id
                  }
             }
            }  
            product_type {
              name
            }
            users{
              id
              first_name
              last_name
              email
              logo
              avatar
              {
                filename_disk
              }
              designer_website
              role
              {
                id
              }
            }
            product_language{
              name
            }
            date_created
        }
    }
`;

export const SingleModalProductQuery = `
  #graphql
  query SingleProduct($product_slug: String, $site_name: String) {
    products(filter: {slug: {_eq: $product_slug}, site_id: { Sites_id: { domain_name: { _eq: $site_name } } }}) {
      id
      title
      price
      description
      slug
      category {
        Product_categories_cat_id {
          cat_id
          category_name
          slug
          child{
            category_name
            cat_id
          }
        }
      }
      thumbnail{
        id
      }
      product_type{
        name
      }
      product_language{
        name
      }
      related_products(limit: 7) {
        related_products_id{
          id
          price
          slug
          title
          thumbnail {
            id
          }
        }
      }
      users{
        id
        first_name
        last_name
        email
        role{
          id
        }
        logo
        avatar{
          filename_disk
        }
        designer_website
      }
      date_created
    }
  }
`;

export const SingleProductByIdQuery = `
    #graphql
    query SingleProductById($product_id: ID!) {
      products_by_id(id: $product_id) {
        id
        title
        price
        slug
        tags
        date_created
        status
        site_id{
        Sites_id{
        id
        algolia_products_indexname  
		    algolia_categories_indexname
        
        }}
        thumbnail{
          id
        }
    		description
    		category
    		{
          Product_categories_cat_id
          {
            category_name
          }
        }
      }
    }
`;

export const AllCategories = `
    #graphql
    query Categories {
      Product_categories(filter:{status: { _in: ["published"]}}) {
            category_name
          	cat_id
            slug
        }
    }
`;

export const CategoryProductsQuery = `
    #graphql
    query CategoryProducts($category_slug: String, $site_name: String, $limit: Int) {
        products(
            filter: {
              status: { _eq: "published"},          
              product_type:{id: {_neq: null }},
              site_id: { Sites_id: { domain_name: { _eq: $site_name } } },
              category: { Product_categories_cat_id: { slug: { _eq: $category_slug } } }
            },
            limit:$limit,
            sort:["product_type.sort", "-date_created"]
          ) {
            id
            title
            price
            description
            slug
            thumbnail {
              id
            }
            category {
              Product_categories_cat_id {
                category_name
                slug
                child {
                  category_name
                  slug
                }
              }
            }
            product_language{
              name
            }
            product_type
            {
              name
              sort
            }
            users{
              id
              first_name
              last_name
              email
              logo
              avatar
              {
                filename_disk
              }
              designer_website
              role
              {
                id
              }
            }
            date_created
          }
    }
`;

export const PaginatedProductsQuery = `
#graphql
query CategoryProducts(
  $category_slug: String
  $site_name: String
  $pageNumber: Int
  $status: [String]
  $productNames: [String]
  $productLanguages: [String]
  $limit: Int
) {
  products(
    filter: {
      status: { _in: $status }
      product_type: { name: { _in: $productNames } }
      site_id: { Sites_id: { domain_name: { _eq: $site_name } } }
      category: { Product_categories_cat_id: { slug: { _eq: $category_slug } } }
      product_language: {name: { _in: $productLanguages }}
    }
    limit: $limit
    page: $pageNumber
    sort: ["product_type.sort", "-date_created"]
  ) {
    id
    title
    price
    description
    slug
    thumbnail {
      id
    }
    category {
      Product_categories_cat_id {
        category_name
        slug
      }
    }
    product_language {
      name
    }
    product_type {
      name
      sort
    }
    users {
      id
      first_name
      last_name
      email
      logo
      avatar {
        filename_disk
      }
      designer_website
      role {
        id
      }
    }
    related_products {
      related_products_id {
        id
        price
        slug
        title
        thumbnail {
          id
        }
      }
    }
    date_created
  }
}
`;

export const FreePaginatedProductsQuery = `
#graphql
query CategoryProducts(
  $category_slug: String
  $site_name: String
  $pageNumber: Int
  $status: [String]
  $productNames: [String]
  $productLanguages: [String]
  $limit: Int
) {
  products(
    filter: {
      status: { _in: $status }
      product_type: { name: { _in: $productNames } }
      site_id: { Sites_id: { domain_name: { _eq: $site_name } } }
      category: { Product_categories_cat_id: { slug: { _eq: $category_slug } } }
      product_language: {name: { _in: $productLanguages }}
      price: {_eq: 0}
    }
    limit: $limit
    page: $pageNumber
    sort: ["product_type.sort", "-date_created"]
  ) {
    id
    title
    price
    description
    slug
    thumbnail {
      id
    }
    category {
      Product_categories_cat_id {
        category_name
        slug
      }
    }
    product_language {
      name
    }
    product_type {
      name
      sort
    }
    users {
      id
      first_name
      last_name
      email
      logo
      avatar {
        filename_disk
      }
      designer_website
      role {
        id
      }
    }
    related_products {
      related_products_id {
        id
        price
        slug
        title
        thumbnail {
          id
        }
      }
    }
    date_created
  }
}
`;

export const CategoryAllProductsQuery = `
  #graphql
  query CategoryProducts($category_slug: String, $site_name: String) {
    products(
      filter: {
        product_type: { id: { _neq: null } }
        site_id: { Sites_id: { domain_name: { _eq: $site_name } } }
        category: { Product_categories_cat_id: { slug: { _eq: $category_slug } } }
      }
      limit: 5
      sort: ["product_type.sort", "-date_created"]
    ) {
      id
      title
      price
      description
      slug

      product_language {
        name
      }
      product_type {
        name
        sort
      }
      users {
        id
        first_name
        last_name
        email
        logo
        avatar {
          filename_disk
        }
        designer_website
        role {
          id
        }
      }
    }
  }

`;

export const CategoryContentQuery = `
  #graphql
  query CategoryContent($category_slug: String, $domain: String) {
    Product_categories(
      filter: {
        slug: { _eq: $category_slug }
        status: { _in: ["published"] }
        site_id: { Sites_id: { domain_name: { _eq: $domain } } }
      }
    ) {
      cat_id
      category_name
      category_description
      meta_title
      category_image {
        id
      }
      category_icon {
        id
      }
      featured_product {
        thumbnail {
          id
        }
      }
      child(
        filter: {
          status: { _in: ["published"] }
          site_id: { Sites_id: { domain_name: { _eq: $domain } } }
        }
      ) {
        slug
        cat_id
        category_name
        category_icon {
          id
        }
        parent {
          slug
        }
      }
    }
  }
`;

export const RelatedCategoryProductsQuery = `
#graphql
query CategoryProducts(
  $category_slugs: [String]
  $product_slug: String
  $site_id: GraphQLStringOrFloat
  $limit: Int
) {
  products(
    filter: {
      slug: { _neq: $product_slug }
      status: { _eq: "published" }
      category: { Product_categories_cat_id: { slug: { _in: $category_slugs } } }
      thumbnail: { id: { _neq: null } }
      site_id: { Sites_id: { id: { _eq: $site_id } } }
    }
    limit: $limit,sort:"-date_created"
  ) {
    id
    title
    price
    slug
    thumbnail {
      id
    }
    category {
      Product_categories_cat_id {
        category_name
        slug
        child {
          category_name
          slug
        }
      }
    }
    product_type {
      name
    }
  }
}
`;

export const Templates = `
    #graphql
    query Templates {
      custom_templates {
       id
       name
       Width
       Height
       background_image
       {
         id
       }
       }
    }
`;

export const Cliparts = `
    #graphql
    query Cliparts {
      clipart
      {
        clipart_title
        clipart_image{
              id
        }
      }
    }
`;

export const FilterCliparts = `
query Cliparts($searchValue: String) {
  clipart(filter:{_or:[{clipart_title: {_contains: $searchValue}},{tags: {_contains: $searchValue}}]})
  {
    clipart_title
    clipart_image
    {
      id
    }
  }
}`;

export const Shapes = `
    #graphql
    query Shapes {
      shapes(filter: {status: {_eq: "published"}},limit: 300) {
        shape_title
        shape_icon{
          id
        }
      }
    }
`;
export const DefaultElementsQuery = `
    #graphql
    query Site_Default_Elements {
      Site_Default_Elements(filter: {status: {_eq: "published"}}) {
        
        category_id{
          category_name
        }
        photos(limit: 3){
              id
              photo_sort
              photos_id{
              id
              preview_photo{
              id
              }
              }
          }
           shapes(limit: 3){
              id
              
              shapes_id{              
              shape_icon{
              id
              }
              }
          } 
            icons(limit: 3){
              id
              
              icons_id{              
              icon_file{
              id
              }
              icon_svg
              }
          }   
            clipart(limit: 3){
                  id         
              clipart_id{              
              clipart_image{
              id
              
              }
              }
          } 
               lines(limit: 3){
                 id          
              lines_id{              
              line_image{
              id
              }
              line_json
              }
          }              
            
      }
    }
`;

export const SingleProductBackgrounds = `
    #graphql
    query SingleProductBackgrounds($product_slug: String) {
        products(filter: {slug: {_eq: $product_slug}}) {
            id
            backgrounds
            {
              id
              directus_files_id
              {
                id
              }
            }
        }
    }
`;

export const SingleProjectProductBackgrounds = `
    #graphql
    query SingleProjectProductBackgrounds($product_id: ID!) {
      products_by_id(id: $product_id) {
        backgrounds
        {
          id
          directus_files_id
          {
            id
          }
        }
      }
    }
`;

export const Fonts = `
    #graphql
    query Fonts {
      allfonts(limit: 1000,sort:"fontname")
      {
        id
        fontname
        fontfile
        {
          id
          filename_disk
        }
      }
    }
`;

export const Texts = `
    #graphql
    query Texts {
      text_templates {
        id
        template_name
        dark_template_preview{
          id
        }
        template_preview {
          id
        }
        template_json
      }
    }
`;

export const CustomTexts = `
    #graphql
    query CustomTexts {
      autofill_data
      {
        name
        type
        key
        default_value
        user_field_key
        data_type
      }
    }
`;

export const Producttype = `
    #graphql
    query Producttype
    {
      product_type(filter: {status: { _in: ["published"]}})
      {
        id
        name
        width
        height
      }
    }
`;

export const CustomTextsManual = `
    #graphql
    query CustomTexts {
      autofill_data(filter: {data_type: {_eq: "manual"}})
      {
        key       
        data_type
        manual_data
      }
    }
`;

export const User = `
    #graphql
    query User($useremail: String){
      Users(filter: {email: {_eq: $useremail}}) {
        id
        first_name
        last_name
        email
        phone_number
        website
        organization_name
        leader_name
        address_city
        address_state
        address_street
        address_zip_code
        logo
        role
      }
    }
`;

export const GetAllProducts = `
  #graphql
  query GetAllProducts($domain: String!) {
    products(
      limit: -1
      filter: {site_id: {Sites_id: {domain_name: {_eq: $domain}}}}
    ) {
      slug
    }
  }

`;

export const GetAllProductImages = `
    #graphql
    query GetAllProducts
    {
      products(limit: 5, filter: {tagged: { _eq: false },status: { _in: ["published"]}}) {
        id
        tags
        thumbnail {
          id
        }
      }
    }
`;

export const GetAllProductsWithoutDescription = `
    #graphql
    query GetAllProducts
    {
      products(limit: 1000, filter: {description: { _null: true }}) {
        id
        title
        description
        product_type{
          name
        }
      }
    }
`;
export const GetAllProductsForAlgolia = `
    #graphql
    query GetAllProducts
    {
      products(limit: 5000, filter: {status: { _in: ["published"]}}) {
        id
        title
        date_created
        description
        tags
        price
        status
         product_type{
          name
        }
        product_language{
          name
        }

        slug
        site_id{
        Sites_id{
        id
        algolia_products_indexname  
		    algolia_categories_indexname
        
        }
        }
        thumbnail {
          id
        }
        category{
          Product_categories_cat_id
          {
            cat_id
            category_name
            slug
          }
        }
      }
      
    } ,
`;

export const GetPageData = `
  #graphql
  query GetPageData($pageID: ID!){
    pages_by_id(id: $pageID){
      id 
      page_content
      page_title
    }
  }
`;

export const GetTermsData = `
  #graphql
  query GetTermsData($domain: String){
    user_terms(filter: { status: {_eq: "published"} domain_name: { domain_name: { _eq: $domain }  } } 
  ){
      id
      terms
      title
    }
  }
`;

export const GetFAQPage = `
  #graphql
  query GetFAQPage{
    faqpage{
      general_info
    }
  }
`;

export const GetFAQItems = `
  #graphql
  query GetFAQItems($domain: String) {
    faq_items(filter: { _and: [{ status: {_eq: "published"} }, {site_id: {Sites_id: {domain_name: {_eq: $domain}}}}] }) {
      id
      question
      answer
      site_id {
        id
      }
    }
  }
`;

export const GetOldRelatedProducts = `
  #graphql
  query GetOldRelatedProducts(
    $siteId: GraphQLStringOrFloat
    $productSlug: String
    $categorySlugs: [String]
    $languageName: String
    $dateCreated: String!
  ) {
    products(filter: {
      slug: { _neq: $productSlug }
      status: { _eq: "published" }
      category: { Product_categories_cat_id: { slug: { _in: $categorySlugs } } }
      product_language: {
        name: {
          _eq: $languageName
        }
      }
      site_id: { Sites_id: { id: { _eq: $siteId } } }
      date_created: {
        _lte: $dateCreated
      }
    }
      sort: "-date_created"
      limit: 10) {
      id
      title
      price
      description
      slug
      status
      show_replace_image_popup
      thumbnail {
        id
      }
      related_products {
        related_products_id {
          id
          price
          slug
          title
          thumbnail {
            id
          }
        }
      }
      date_created
    }
  }
`;

export const GetNewRelatedProducts = `
  #graphql
  query GetNewRelatedProducts(
    $siteId: GraphQLStringOrFloat
    $productSlug: String
    $categorySlugs: [String]
    $languageName: String
    $dateCreated: String!
  ) {
    products(filter: {
      slug: { _neq: $productSlug }
      status: { _eq: "published" }
      category: { Product_categories_cat_id: { slug: { _in: $categorySlugs } } }
      product_language: {
        name: {
          _eq: $languageName
        }
      }
      site_id: { Sites_id: { id: { _eq: $siteId } } }
      date_created: {
        _gte: $dateCreated
      }
    }
      sort: "date_created"
      limit: 10) {
      id
      title
      price
      description
      slug
      status
      show_replace_image_popup
      thumbnail {
        id
      }
      related_products {
        related_products_id {
          id
          price
          slug
          title
          thumbnail {
            id
          }
        }
      }
      date_created
    }
  }
`;

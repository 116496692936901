export const viewItem = (price, { item_id, item_name, item_price }) => {
  const dataLayer = window.dataLayer;
  if (!dataLayer) return;

  dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  dataLayer.push({
    event: 'view_item',
    ecommerce: {
      value: price,
      currency: 'USD',
      items: [
        {
          item_id,
          item_name,
          price: item_price,
        },
      ],
    },
  });
  console.log(dataLayer);
};

export const sign_up = () => {
  const dataLayer = window.dataLayer;
  if (!dataLayer) return;

  dataLayer.push({
    event: 'sign_up', // Standard GA4 event name
    method: 'email',  // Recommended parameter by GA4
  });

  console.log(dataLayer);
};

export const download_product = ({ item_id, item_name, user }) => {
  const dataLayer = window.dataLayer;
  if (!dataLayer) return;

  dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  dataLayer.push({
    event: 'download_product',
    ecommerce: {
      items: [
        {
          item_id,
          item_name,
          user: user,
        },
      ],
    },
  });

  console.log(dataLayer);
};

export const PaidSubscription = ({ item_name }) => {
  const dataLayer = window.dataLayer;
  if (!dataLayer) return;

  dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  dataLayer.push({
    event: 'paid_subscription',
    subscription: {
      subscription_type: item_name,
    },
  });
  console.log(dataLayer);
};

export const addToCart = (price, { item_id, item_name, item_price }) => {
  const dataLayer = window.dataLayer;
  if (!dataLayer) return;

  dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  dataLayer.push({
    event: 'add_to_cart',
    ecommerce: {
      value: price,
      currency: 'USD',
      items: [
        {
          item_id,
          item_name,
          price: item_price,
        },
      ],
    },
  });
  console.log(dataLayer);
};

export const initiatePayment = (price, { item_id, item_name, item_price }) => {
  const dataLayer = window.dataLayer;
  if (!dataLayer) return;

  dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  dataLayer.push({
    event: 'begin_checkout',
    ecommerce: {
      value: price,
      currency: 'USD',
      items: [
        {
          item_id,
          item_name,
          price: item_price,
        },
      ],
    },
  });
  console.log(dataLayer);
};

export const purchaseEvent = (
  tx_id,
  price,
  { item_id, item_name, item_price },
) => {
  const dataLayer = window.dataLayer;
  if (!dataLayer) return;

  dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  dataLayer.push({
    event: 'purchase',
    ecommerce: {
      transaction_id: tx_id,
      value: price,
      currency: 'USD',
      items: [
        {
          item_id,
          item_name,
          price: item_price,
        },
      ],
    },
  });

  console.log(dataLayer);
};

// export const checkoutCallback = (price, {item_id, item_name, item_price}, url, window) => {
//     const dataLayer = window.dataLayer;
//     if(!dataLayer) return;

//     dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
//     dataLayer.push({
//         event: "begin_checkout",
//         eventCallback:  function(){
//             window.location.href = url;
//         },
//         ecommerce: {
//             value: price,
//             currency: "USD",
//             items: [
//             {
//                 item_id,
//                 item_name,
//                 price: item_price,
//             }]
//         }
//     });
// }

import { toast } from 'react-toastify';

export const checkURLforErrors = (router) => {
  const { query, pathname } = router;
  const { toast_error } = router.query;

  if (toast_error) {
    const errorList = {
      1: 'Your saved design limit is reached. Please delete old designs, if you want to save new designs',
      2: 'Error Creating New Project. Please Try Again',
      3: 'The product you are trying to view is currently unavailable.',
      4: 'This product does not exist.',
      5: 'This page is not available at this time.',
      6: 'No token found in URL',
      7: 'Failed to create design. Please Try Again',
    };
    toast.error(errorList[toast_error]);
    // Remove the 'yourQueryParam' from the query
    delete query.toast_error;

    // Use the replace method to update the URL without a page refresh
    router.replace({
      pathname,
      query,
    });
  }
};

export const isValidURL = (text) => {
  const urlPattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol (optional)
      '((([a-zA-Z0-9$-_@.&+!*\\(\\),]+)@)?' + // user info (optional)
      '([a-zA-Z0-9.-]+)\\.' + // domain name (mandatory)
      '([a-zA-Z]{2,}))' + // top-level domain (mandatory)
      '(:\\d{2,5})?' + // port number (optional)
      '(\\/[^\\s]*)?$', // path (optional)
    'i', // case-insensitive flag
  );
  return urlPattern.test(text);
};

export const isBase64Url = (str) => {
  const regex = /^data:image\/[a-zA-Z]+;base64,[A-Za-z0-9+/=]+$/;
  return regex.test(str);
};
